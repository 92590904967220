<template>
  <div>
    <header-top-dashboard primary>
      <v-row class="mt-0">
        <v-col
          v-for="(item, i) in miniCards"
          :key="i"
          cols="12"
          lg="3"
          md="6"
          class="py-0"
        >
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-stats-padding">
              <v-row no-gutters>
                <v-col>
                  <div
                    class="font-weight-600 text-muted text-uppercase text-h5"
                  >
                    {{ item.subtitle }}
                  </div>
                  <p class="font-weight-600 text-h2 text-typo mb-0">
                    {{ item.title }}
                  </p>
                </v-col>
                <v-col class="text-right">
                  <v-avatar :color="item.gradient">
                    <v-icon size="20" class="text-white">
                      {{ item.icon }}
                    </v-icon>
                  </v-avatar>
                </v-col>
              </v-row>

              <p class="mt-4 mb-0 text-body font-weight-light">
                <span class="text-success me-2">3.48%</span> Since last month
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </header-top-dashboard>

    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Overview
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Total Sales</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="salesChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Performance
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Total Orders</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="ordersChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Growth
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">Sales Value</p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="dotsChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Users
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">
                Audience overview
              </p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="doughnutChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Partners
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">
                Affiliate Traffic
              </p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="pieChartID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card class="card-shadow">
            <div class="card-header-padding card-border-bottom">
              <div
                class="font-weight-600 text-muted text-uppercase text-h6 ls-1 mb-1"
              >
                Overview
              </div>
              <p class="font-weight-600 text-typo text-h3 mb-0">
                Product Comparison
              </p>
            </div>
            <v-card-text class="card-padding">
              <div class="chart-area">
                <canvas :height="350" :id="barChartStackedID"></canvas>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import HeaderTopDashboard from "../../components/HeaderTopDashboard.vue";
import {
  salesChart,
  ordersChart,
  dotsChart,
  doughnutChart,
  pieChart,
  barChartStacked,
} from "@/components/Charts/Chart";
export default {
  name: "charts",
  components: {
    HeaderTopDashboard,
  },
  data() {
    return {
      salesChartID: "salesChartID",
      ordersChartID: "ordersChartID",
      dotsChartID: "dotsChartID",
      doughnutChartID: "doughnutChartID",
      pieChartID: "pieChartID",
      barChartStackedID: "barChartStacked",
      miniCards: [
        {
          subtitle: "Total Traffic",
          title: "350,897",
          gradient: "bg-gradient-danger",
          icon: "ni-active-40",
        },
        {
          subtitle: "New Users",
          title: "2,356",
          gradient: "bg-gradient-warning",
          icon: "ni-chart-pie-35",
        },
        {
          subtitle: "Sales",
          title: "924",
          gradient: "bg-gradient-success",
          icon: "ni-money-coins",
        },
        {
          subtitle: "Performance",
          title: "49,65%",
          gradient: "bg-gradient-success",
          icon: "ni-chart-bar-32",
        },
      ],
    };
  },
  mounted() {
    pieChart.createChart(this.pieChartID);
    doughnutChart.createChart(this.doughnutChartID);
    dotsChart.createChart(this.dotsChartID);
    salesChart.createChart(this.salesChartID);
    ordersChart.createChart(this.ordersChartID);
    barChartStacked.createChart(this.barChartStackedID);
  },
};
</script>
